import React, { useContext } from 'react';
import img40 from '../assets/images/logo (1).png'
import { ModalContext } from '../components/ModalProvider';
import config from '../config/config'
function Navbar() {
    const { openModal } = useContext(ModalContext);
    return (
        <>
            <nav className="relative py-4 md:px-10 px-4">

                <div className="relative hidden py-[18px] lg:block max-w-7xl mx-auto">
                    <div className="grid items-center grid-cols-12 gap-5">
                        <div className="col-span-12 lg:col-span-6">
                            <a href="/">
                                <img className="brightness-0 invert" height="" loading="lazy"
                                    src={img40} alt="" />                                </a>
                        </div>
                        
                        <div className="col-span-12 lg:col-span-6  ">
                            <div className="flex flex-wrap items-center float-right">
                                <div>
                                    <h1 className="text-[#00c581] lg:text-[17px] text-[15px]">
                                        Call Us
                                    </h1>
                                    <a className=" text-white text-[24px] text-[20px]" href={`tel:${config.phoneNumberLink}`}>
                                        {config.phoneNumberDisplay}
                                    </a>
                                </div>
                                <div className="lg:ml-[10px] my-2">
                                    <a onClick={openModal} className="  lg:px-12 px-4 py-4 bg-transparent hover:bg-[#00c581] lg:text-[15px] text-[13px] tracking-tighter-[1] text-white border-2 border-[#44c97c] rounded-full"
                                        href="javascript:;" >LET'S GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block py-2 lg:hidden lg:py-5 ">
                    <div className="grid items-center grid-cols-12 px-2 sm:gap-5">
                        <div className="md:col-span-5 col-span-12">
                            <a className="" href="/">
                                <img className="lg:mx[0] mx-auto brightness-0 invert" height="" loading="lazy"
                                    src={img40} alt="" />                </a>
                        </div>
                        <div className="col-span-7">
                            <div className="flex flex-col">
                                {/* <div className="flex flex-row items-end justify-center lg:items-center">
                                    <button aria-controls="mobile-menu" aria-expanded="false" aria-label="Menu Toggle" className="w-full navbtn inline-flex justify-center items-center lg:ml-3 rounded-lg lg:hidden focus:outline-none lg:mr-4 rounded-[4px] custombg4 relative z-[0] before:rounded-[4px] before:w-full focus:ring-none" data-collapse-toggle="mobile-menu" type="button">
                                        <svg className="w-7 h-7 bg-[#16295C] backdrop-blur px-1 rounded-[2px]" fill="#ffffff" viewbox="0 0 20 20">
                                            <path className="block minus" clip-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" fill-rule="evenodd"></path>
                                            <path className="hidden plus" clip-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" fill-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                </div> */}
                                <nav className="flex-col hidden flex-grow pb-4 absolute top-[50%] left-0 border-[#00c581] bg-[#16295c]/90 border border-t-[0] border-b-[5px]  drop-shadow-md rounded-[5px] w-[90%] z-50 py-4 my-10 left-[50%] translate-x-[-50%]" id="mobile-menu">
                                    <div className="flex flex-col px-2 gap-y-3">
                                        <a className="px-1" href="javascript:;">
                                            <span className="uppercase text-[#ffffff] 2xl:text-[14px] text-[12px] font-[600] tracking-[1px] relative before:content-[''] before:absolute before:bottom-[-3px] before:w-[100%] before:h-[2px] before:rounded-[4px]  before:bg-[#00c581] before:origin-right before:scale-x-0 before:hover:origin-left before:hover:scale-x-[1] relative before:transition-transform before:ease-in-out before:duration-200">
                                                HOME</span>
                                        </a>
                                        <a className="px-1" href="about-us">
                                            <span className="uppercase text-[#ffffff] 2xl:text-[14px] text-[12px] font-[600] tracking-[1px] relative before:content-[''] before:absolute before:bottom-[-3px] before:w-[100%] before:h-[2px] before:rounded-[4px]  before:bg-[#00c581] before:origin-right before:scale-x-0 before:hover:origin-left before:hover:scale-x-[1] relative before:transition-transform before:ease-in-out before:duration-200">
                                                ABOUT </span>
                                        </a>
                                        <div className="relative group">
                                            <a className="relative px-1 group" href="javascript:;">
                                                <span className="uppercase text-[#ffffff] 2xl:text-[14px] text-[12px] font-[600] tracking-[1px] relative before:content-[''] before:absolute before:bottom-[-3px] before:w-[100%] before:h-[2px] before:rounded-[4px]  before:bg-[#00c581] before:origin-right before:scale-x-0 before:hover:origin-left before:hover:scale-x-[1] relative before:transition-transform before:ease-in-out before:duration-200">
                                                    SERVICES
                                                </span>
                                                <svg fill="currentcolor" viewBox="0 0 20 20" className="inline w-4 h-4 mt-0 group-hover:rotate-180 text-[#AEAEAE] group-hover:text-[#ffffff]">
                                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                                </svg>
                                            </a>
                                            <div className=" z-40 hidden max-w-[250px] min-w-[250px] pt-3 transition duration-150 ease-out group-hover:block hover:ease-in left-0 overflow-hidden">
                                                <div className="px-3 py-2  rounded-[10px] relative z-50 border-[#00c581] bg-[#16295c]/90 border border-t-[0] border-b-[5px]">
                                                    <div className="grid grid-cols-12 px-2 py-3 gap-y-3">
                                                        <div className="col-span-12">
                                                            <ul>
                                                                <li>
                                                                    <a className="block mb-2 lg:text-[16px] text-[15px] text-white capitalize before:content-[''] before:absolute before:bottom-[-3px] before:w-[100%] before:h-[2px] before:rounded-[4px]  before:bg-[#00c581] before:origin-right before:scale-x-0 before:hover:origin-left before:hover:scale-x-[1] relative before:transition-transform before:ease-in-out before:duration-200" href="article-writing">
                                                                        article writing
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="block mb-2 lg:text-[16px] text-[15px] text-white capitalize before:content-[''] before:absolute before:bottom-[-3px] before:w-[100%] before:h-[2px] before:rounded-[4px]  before:bg-[#00c581] before:origin-right before:scale-x-0 before:hover:origin-left before:hover:scale-x-[1] relative before:transition-transform before:ease-in-out before:duration-200" href="book-cover">
                                                                        book cover
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="block mb-2 lg:text-[16px] text-[15px] text-white capitalize before:content-[''] before:absolute before:bottom-[-3px] before:w-[100%] before:h-[2px] before:rounded-[4px]  before:bg-[#00c581] before:origin-right before:scale-x-0 before:hover:origin-left before:hover:scale-x-[1] relative before:transition-transform before:ease-in-out before:duration-200" href="editing-services">
                                                                        editing services
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a className="px-1" href="contact-us">
                                            <span className="uppercase text-[#ffffff] 2xl:text-[14px] text-[12px] font-[600] tracking-[1px] relative before:content-[''] before:absolute before:bottom-[-3px] before:w-[100%] before:h-[2px] before:rounded-[4px]  before:bg-[#00c581] before:origin-right before:scale-x-0 before:hover:origin-left before:hover:scale-x-[1] relative before:transition-transform before:ease-in-out before:duration-200">
                                                Contact Us </span>
                                        </a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

        </>
    );
}
export default Navbar;