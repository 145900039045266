import React, { useState } from 'react';
import CustomModal from '../components/customModal';

export const ModalContext = React.createContext();

const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <CustomModal isOpen={isModalOpen} onClose={closeModal} />
    </ModalContext.Provider>
  );
};

export default ModalProvider;