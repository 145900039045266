// CustomModal.js

import React from 'react';
import Modal from 'react-modal';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useState } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import config from '../config/config'

const CustomModal = ({ isOpen, onClose }) => {

    const navigate = useNavigate();

    
    const [ip, setIp] = useState('');
    const [isp, setIsp] = useState('');
    const [org, setOrg] = useState('');
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    
    
    // Define the API endpoint URL
const apiUrl = 'https://ipwhois.pro/json/?key=LLjAABw4K0gjEPw6';

// Fetch data from the API
fetch(apiUrl)
  .then(response => {
    // Check if the response is successful
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // Parse the JSON response
    return response.json();
  })
  .then(data => {
    // Access the data and extract information
    setIp(data.ip);
    setIsp(data.isp);
    setOrg(data.org);
    setCountry(data.country);
    setRegion(data.region);
    setCity(data.city);
    // Access other relevant information as needed

    // Output the information
    // console.log('IP Address:', ip);
    // console.log('ISP:', isp);
    // Output other relevant information as needed
  })
  .catch(error => {
    // Handle errors
    console.error('Error fetching data:', error);
  });

    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  
  const [message, setMessage] = useState('');
  

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Perform additional validation here if needed
    
    // Submit the form if validation passes
    // Here you can send the form data to your server-side script (send.php)
    // using fetch or any other method
    fetch(config.apiUrl, {
      method: 'POST',
      body: JSON.stringify({
        name: name,
        email: email,
        phone_number: phone,
        message: message,
        ip2loc_ip: ip,
        ip2loc_isp: isp,
        ip2loc_org: org,
        ip2loc_country: country,
        ip2loc_region: region,
        ip2loc_city: city,
        landing_page: "https://ghostwritingmentors.com/"
      })
    })
    .then(response => response.json())
    .then(data => {
      // Handle response from server if needed
      console.log(data);
      if(data.id != null){
        // alert("success");
        onClose();
        navigate('/thankyou');
        
      }
    })
    .catch(error => {
      // Handle error if fetch fails
      console.error('Error:', error);
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="bg-[#000]/40 fixed top-0 z-[999999] left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 mx-auto flex justify-center items-center ">
      {/* Your modal content here */}
        <div id="" tabindex="-1" className="">
            <div className="">
                <div
                    className="relative bg-[#fff] w-full max-w-[850px] h-auto z-[0] mx-auto rounded-[10px] px-4 py-4 md:min-w-[600px]">
                    <button type="button" aria-label="button"
                        onClick={onClose}
                        className="absolute top-[-10px] right-[-10px] text-[#023047]  rounded-full text-sm p-1.5 ml-auto inline-flex items-center bg-[#00c581] z-[3]"
                    >
                        <svg aria-hidden="true" className="w-5 h-5" fill="#fff" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"></path>
                        </svg>
                    </button>
                    <div className="grid items-center grid-cols-12 gap-2">
                        <div
                            className="col-span-12 relative h-full lg:rounded-r-[30px] lg:rounded-l-none rounded-[20px] px-3 md:px-4">
                            <div
                                className="flex flex-col items-start justify-center h-full lg:px-0 md:py-0 py-5 md:pt-5 lg:w-12/12 mx-auto">
                                <h4 className="text-[#000] lg:text-[36px] text-[30px] bold leading-[1.1]">
                                    We Have The <span className="text-[#000]">Best Offer!</span>
                                </h4>
                                <p className="text-[#000] lg:text-[18px] text-[14px] font-light mb-6">
                                    Enjoy a festive 30% off on all your wishes.
                                </p>
                                <form onSubmit={handleSubmit} className="w-full validate-form-2">
                                    <div className="grid grid-cols-12 gap-5 gap-y-3">
                                        <div className="col-span-12">
                                        <input
                                            type="text"
                                            className="h-[50px] bg-[#f5f5f5] required px-5 placeholder:text-[16px] placeholder:text-[#555] border-[1px] border-[#555] focus:outline-none w-full rounded-[10px] montserrat"
                                            placeholder="Full Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                        </div>
                                        <div className="col-span-12">
                                        <input
                                            type="email"
                                            className="h-[50px] bg-[#f5f5f5] required px-5 placeholder:text-[16px] placeholder:text-[#555] border-[1px] border-[#555] focus:outline-none w-full rounded-[10px] montserrat"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        </div>
                                        <div className="col-span-12">
                                        <PhoneInput
                                        defaultCountry="ua"
                                        value={phone}
                                        onChange={(phone) => setPhone(phone)}
                                    />
                                        </div>
                                        <div className="col-span-12">
                                        <textarea
                                            name="message"
                                            placeholder="Your Message"
                                            cols="30"
                                            rows="7"
                                            className="pt-5 bg-[#f5f5f5] px-5 placeholder:text-[16px] placeholder:text-[#555] border-[1px] border-[#555] focus:outline-none w-full rounded-[10px] montserrat"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        ></textarea>
                                        </div>
                                        <div className="col-span-12 mb-3">
                                        <div className="text-center">
                                            <button
                                            type="submit"
                                            className="bg-[#00c581] text-[#fff] px-14 py-3 inline-block text-[14px] bold tracking-[2px] uppercase"
                                            >
                                            Submit Form
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
  );
};

export default CustomModal;