import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img40 from '../assets/images/logo (1).png';
import {Navigate, useNavigate} from 'react-router-dom';

export default function Thankyou() {

    const navigate = useNavigate();

    const backToHomepage = () => {
        navigate('/');
    }
    
  
  
  return (
    <>
    <section
        class="thankyou-banner text-center flex flex-col items-center justify-center min-h-[100vh] w-full  bg-cover border-t-[5px] border-[#991300] bg-right py-[50px]">
        <div class="max-w-[1140px] mx-auto px-3 ">
            <a href="javascript:;" class="inline-block mx-auto logo-thankyou">
                <img src={img40}
                    alt=""/></a>
            <h1 class="block "> <span class="text-[46px] font-[600] leading-[58px] italic">Thank you for Contacting
                    us!</span></h1>
            <p class="text-center text-[#978c8c] poppins text-[22px] leading-[26px] pt-4">Our product support specialist
                will be in touch with you soon for the next steps.</p>

            <a href="javascript:;" 
                class="live-chat-thank chat open_chat_btn bg-[#C21D2E] text-[#ffffff] inline-block rounded-[50px] py-[14px] px-[70px] text-white uppercase tracking-[1px] mt-[40px] mb-[20px] relative overflow-clip z-[0] before:z-[-1] before:content-[''] before:absolute before:bottom-[0px]
                    before:w-[100%] before:h-full before:rounded-full before:bg-[#062B47]  before:right-0
                    before:origin-bottom before:scale-y-0 before:hover:scale-y-[1] relative before:transition-transform before:ease-in-out
                    before:duration-300">Let’s
                Chat</a>
            <div class="clearfix"></div>
            <a onClick={backToHomepage} href="/"
                class="back-home bg-[#062B47] text-[#ffffff] inline-block rounded-[50px] py-[14px] px-[70px]  uppercase tracking-[1px] mb-[40px] mt-[10px] relative overflow-clip z-[0] before:z-[-1] before:content-[''] before:absolute before:bottom-[0px]
                    before:w-[100%] before:h-full before:rounded-full before:bg-[#C21D2E]  before:right-0
                    before:origin-bottom before:scale-y-0 before:hover:scale-y-[1] relative before:transition-transform before:ease-in-out
                    before:duration-300">Back
                To Homepage</a>
        </div>
    </section>
    </>
  );
}