import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/fontawesome/webfonts/fa-brands-400.ttf'
import './assets/fontawesome/webfonts/fa-regular-400.ttf'
import './assets/fontawesome/webfonts/fa-solid-900.ttf'
import './assets/fontawesome/webfonts/fa-v4compatibility.ttf'
import './assets/fontawesome/webfonts/fa-brands-400.woff2'
import './assets/fontawesome/webfonts/fa-regular-400.woff2'
import './assets/fontawesome/webfonts/fa-solid-900.woff2'
import './assets/fontawesome/webfonts/fa-v4compatibility.woff2'
import './assets/fonts/pxiByp8kv8JHgFVrLCz7Z1xlFQ (2).woff2'
import './assets/fonts/pxiByp8kv8JHgFVrLDD4Z1xlFQ (1).woff2'
import './assets/fonts/pxiByp8kv8JHgFVrLEj6Z1xlFQ (1).woff2'
import './assets/fonts/pxiByp8kv8JHgFVrLGT9Z1xlFQ (1).woff2'
import './assets/fonts/pxiEyp8kv8JHgFVrJJfecg (1).woff2'
import './assets/css/style.css'
import './assets/css/owl.carousel.min.css'
import './assets/css/owl.theme.default.min.css'
import './assets/fontawesome/css/all.css'

import reportWebVitals from './reportWebVitals';


const $ = require("jquery")(window);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
