import img40 from '../assets/images/logo (1).png'
import img1 from '../assets/images/amazon.png'
import Navbar from '../components/navbar'
import Timer from '../components/timer'
// import img1 from '../assets/images/bbb-img.png';
import { ModalContext } from '../components/ModalProvider';
import react, { useContext} from 'react'

function Header() {
    const { openModal } = useContext(ModalContext);
    return (
        <>
            <section className="">
                <div className="bg-[url('./assets/images/banner.jpg')] bg-cover bg-no-repeat bg-[length:100%_100%]  lg:pb-20">
                    <  Navbar />
                    <div className=" max-w-7xl mx-auto 2xl:px-0 px-4 counter">
                        <div className="text-center py-5">
                            <div data-timer="" className="CountDownTimer md:block hidden"> <Timer /></div>
                            {/* <div className="CountDownTimer" data-timer="5600"></div> */}
                            <img className="mx-auto lg:mt-5 mt-3" src={img1} alt="" />
                            <h1 className="lg:mt-5 mt-3 lg:text-[42px] text-[22px] font-medium leading-[1.1] text-white">
                                BECOME A <span className="text-[#fe9d00]">AMAZON</span> BEST SELLING
                                AUTHOR!
                            </h1>
                            <p className="lg:mt-5 mt-4 text-[#00c67f] lg:text-[28px] text-[17px] leading-[1.1] font-[600]">
                                Publish your Book on Amazon Today at 85% OFF.
                            </p>
                            <p className="text-white lg:text-[28px] text-[15px] font-[600]  block">
                                TO CREATE YOUR TIMELESS WORK
                            </p>
                            <div className="grid grid-cols-12 lg:mt-4 leading-[0.1] items-center">
                                <div className="lg:col-span-3 col-span-12">
                                    <div className="flex justify-center">
                                        <ul>
                                            <li className="mt-5 gap-2 text-white lg:text-[14px] text-[12px] ">
                                                <i className="fa fa-check lg:text-[17px] text-[8px]  text-green-500 mr-2 "
                                                    aria-hidden="true"></i> TRUSTED BY 400+ AUTHORS
                                                GLOBALLY
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="lg:col-span-3 col-span-12">
                                    <div className="flex justify-center">
                                        <ul>
                                            <li className="mt-5 gap-2 text-white lg:text-[14px] text-[12px] ">
                                                <i className="fa fa-check lg:text-[17px] text-[12px]  text-green-500 mr-2"
                                                    aria-hidden="true"></i> 86% OF
                                                CUSTOMERS REHIRE
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="lg:col-span-3 col-span-12">
                                    <div className="flex justify-center">
                                        <ul>
                                            <li className="mt-5 gap-2 text-white lg:text-[14px] text-[12px] ">
                                                <i className="fa fa-check lg:text-[17px] text-[12px]  text-green-500 mr-2"
                                                    aria-hidden="true"></i> 2.5K+ WRITERS AND EDITORS
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="lg:col-span-3 col-span-12">
                                    <div className="flex justify-center">
                                        <ul>
                                            <li className="mt-5 gap-2 text-white lg:text-[14px] text-[12px] ">
                                                <i className="fa fa-check lg:text-[14px] text-[12px]  text-green-500 mr-2"
                                                    aria-hidden="true"></i>100% SATISFACTION GUARANTEED
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 lg:gap-4 gap-2 flex flex-wrap justify-center">
                                <a onClick={openModal} className=" font-bold lg:px-8 px-4 lg:py-3 py-2 bg-[#ec1200] hover:bg-white hover:text-[#ec1200] lg:text-[17px] text-[14px] text-white rounded-full"
                                    href="#">ACTIVATE YOUR COUPON</a>
                                <a onClick={openModal} className=" font-bold lg:px-8 px-4 lg:py-3 py-2 bg-[#f9ab0e] hover:bg-white hover:text-[#f9ab0e] lg:text-[17px] text-[14px] text-white rounded-full"
                                    href="#" >VIEW 85% OFF PRICING</a>
                            </div>
                            <img className="mx-auto mt-10" src={img1} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Header;
