// import img2 from '../assets/images/autobiography 01.jpg'
// import { useState } from 'react'

// export default function Tabs() {
//     const [toogleState, setToogleState] = useState(1);

//     const toogleTab = () => {
//         console.log("jdjfdgj")
//     }

//     return (
//         <>

//             <section className="my-10">
//                 <div className="bg-[#ededed] lg:py-20 py-5">
//                     <div className="max-w-7xl mx-auto lg:px-0 px-2">
//                         <div className="grid grid-cols-12 ">
//                             <div className="col-span-12 text-center">
//                                 <h1 className="lg:text-[32px] text-[22px] font-[700] text-[#232323] leading-[1.1]">
//                                     We Have Helped Thousands Of Writers Get Their Work Published
//                                 </h1>
//                                 <p className=" mx-auto lg:text-[18px] text-[13px] text-[#676767] mt-5 lg:w-4/6">
//                                     Our diversified talent pool with multiple unique skillsets has
//                                     enabled us to specialize in various genres and cater to the
//                                     ghostwriting needs of clients from varying industries and
//                                     professional spheres.
//                                 </p>
//                             </div>
//                         </div>
//                         <div className="md:w-7/12  lg:px-0 px-8 mx-auto">
//                             <ul className="flex flex-wrap justify-center my-10 lg:gap-0 gap-2 " id="default-tab"
//                                 data-tabs-toggle="#default-tab-content" role="tablist">
//                                 <li className="me-2" role="presentation">
//                                     <button onClick={toogleTab}
//                                         className="lg:px-4 px-2 py-2 bg-[#3cca79] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
//                                         id="slide-1-tab" data-tabs-target="#slide-1" role="tab">FICTION</button>
//                                 </li>
//                                 <li className="me-2" role="presentation" onClick={toogleTab}>
//                                     <button
//                                         className="lg:px-4 px-2 py-2 bg-[#3cca79] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
//                                         id="slide-2-tab" data-tabs-target="#slide-2" role="tab">NON-FICTION</button>
//                                 </li>
//                                 <li className="me-2" role="presentation">
//                                     <button
//                                         className="lg:px-4 px-2 py-2 bg-[#3cca79]  lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px] "
//                                         id="slide-3-tab" data-tabs-target="#slide-3" type="button" role="tab"
//                                         aria-controls="settings" aria-selected="false">BIOGRAPHY</button>
//                                 </li>
//                                 <li className="me-2" role="presentation">
//                                     <button
//                                         className="lg:px-4 px-2 py-2 bg-[#3cca79] hover:bg-[] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
//                                         id="slide-4-tab" data-tabs-target="#slide-7" type="button" role="tab"
//                                         aria-controls="contacts" aria-selected="false">INFORMATIVE</button>
//                                 </li>
//                                 <li className="me-2" role="presentation">
//                                     <button
//                                         className="lg:px-4 px-2 py-2 bg-[#3cca79] hover:bg-[] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
//                                         id="slide-6-tab" data-tabs-target="#slide-6" type="button" role="tab"
//                                         aria-controls="slide-6" aria-selected="false">AUTOBIOGRAPHY</button>
//                                 </li>
//                                 <li className="me-2" role="presentation">
//                                     <button
//                                         className="lg:px-4 px-2 py-2 bg-[#00c581] hover:bg-[#00c581] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
//                                         id="slide-7-tab" data-tabs-target="#slide-7" type="button" role="tab"
//                                         aria-controls="slide-6" aria-selected="false">MEMOIR</button>
//                                 </li>
//                             </ul>
//                         </div>
//                         <div id="default-tab-content">
//                             <div className="hidden grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
//                                 id="slide-1" role="tabpanel" aria-labelledby="slide-1-tab">
//                                 <div className="mx-auto"><a href="assets/images/fiction 01.jpg"><img
//                                     src={img24} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/fiction 02.jpg"><img
//                                     src={img25} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/fiction 03.jpg"><img
//                                     src={img26} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/fiction 04.jpg"><img
//                                     src={img27} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/fiction 05.jpg"><img
//                                     src={img28} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/fiction 06.jpg"><img
//                                     src={img29} /></a></div>
//                             </div>
//                             <div className="hidden grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
//                                 id="slide-2" role="tabpanel" aria-labelledby="slide-2-tab">
//                                 <div className="mx-auto"><a href="assets/images/non-fiction 01.jpg"><img
//                                     src={img47} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/non-fiction 02.jpg"><img
//                                     src={img48} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/non-fiction 03.jpg"><img
//                                     src={img49} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/non-fiction 04.jpg"><img
//                                     src={img50} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/non-fiction 05.jpg"><img
//                                     src={img51} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/non-fiction 06.jpg"><img
//                                     src={img52} /></a></div>
//                             </div>
//                             <div className="hidden grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
//                                 id="slide-3" role="tabpanel" aria-labelledby="slide-3-tab">
//                                 <div className="mx-auto"><a href="assets/images/biography 01.jpg"><img
//                                     src={img2} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/biography 02.jpg"><img
//                                     src={img3} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/biography 03.jpg"><img
//                                     src={img4} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/biography 04.jpg"><img
//                                     src={img5} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/biography 05.jpg"><img
//                                     src={img6} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/biography 06.jpg"><img
//                                     src={img7} /></a>
//                                 </div>
//                             </div>
//                             <div className="hidden grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
//                                 id="slide-4" role="tabpanel" aria-labelledby="slide-4-tab">
//                                 <div className="mx-auto"><a href="assets/images/informative 01.jpg"><img
//                                     src={img30} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/informative 02.jpg"><img
//                                     src={img31} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/informative 03.jpg"><img
//                                     src={img32} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/informative 04.jpg"><img
//                                     src={img33} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/informative 05.jpg"><img
//                                     src={img34} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/informative 06.jpg"><img
//                                     src={img35} /></a></div>
//                             </div>
//                             <div className="hidden grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
//                                 id="slide-6" role="tabpanel" aria-labelledby="slide-6-tab">
//                                 <div className="mx-auto"><a href="assets/images/autobiography 01.jpg"><img
//                                     src={img2} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/autobiography 02.jpg"><img
//                                     src={img3} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/autobiography 03.jpg"><img
//                                     src={img4} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/autobiography 04.jpg"><img
//                                     src={img5} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/autobiography 05.jpg"><img
//                                     src={img6} /></a></div>
//                                 <div className="mx-auto"><a href="assets/images/autobiography 06.jpg"><img
//                                     src={img7} /></a></div>
//                             </div>
//                             <div className="hidden grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
//                                 id="slide-7" role="tabpanel" aria-labelledby="slide-7-tab">
//                                 <div className="mx-auto"><a href="assets/images/memoir 01.jpg"><img
//                                     src={img41} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/memoir 02.jpg"><img
//                                     src={img42} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/memoir 03.jpg"><img
//                                     src={img43} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/memoir 04.jpg"><img
//                                     src={img44} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/memoir 05.jpg"><img
//                                     src={img45} /></a>
//                                 </div>
//                                 <div className="mx-auto"><a href="assets/images/memoir 06.jpg"><img
//                                     src={img46} /></a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//         </>
//     )

// }
import { Tabs } from 'flowbite-react';

import img24 from '../assets/images/fiction 01.jpg'
import img25 from '../assets/images/fiction 02.jpg'
import img26 from '../assets/images/fiction 03.jpg'
import img27 from '../assets/images/fiction 04.jpg'
import img28 from '../assets/images/fiction 05.jpg'
import img29 from '../assets/images/fiction 06.jpg'
import img47 from '../assets/images/non-fiction 01.jpg'
import img48 from '../assets/images/non-fiction 02.jpg'
import img49 from '../assets/images/non-fiction 03.jpg'
import img50 from '../assets/images/non-fiction 04.jpg'
import img51 from '../assets/images/non-fiction 05.jpg'
import img52 from '../assets/images/non-fiction 06.jpg'
import img41 from '../assets/images/memoir 01.jpg'
import img42 from '../assets/images/memoir 02.jpg'
import img43 from '../assets/images/memoir 03.jpg'
import img44 from '../assets/images/memoir 04.jpg'
import img45 from '../assets/images/memoir 05.jpg'
import img46 from '../assets/images/memoir 06.jpg'
import img30 from '../assets/images/informative 01.jpg'
import img31 from '../assets/images/informative 02.jpg'
import img32 from '../assets/images/informative 03.jpg'
import img33 from '../assets/images/informative 04.jpg'
import img34 from '../assets/images/informative 05.jpg'
import img35 from '../assets/images/informative 06.jpg'
import img8 from '../assets/images/biography 01.jpg'
import img9 from '../assets/images/biography 02.jpg'
import img10 from '../assets/images/biography 03.jpg'
import img11 from '../assets/images/biography 04.jpg'
import img12 from '../assets/images/biography 05.jpg'
import img13 from '../assets/images/biography 06.jpg'
import img2 from '../assets/images/autobiography 01.jpg'
import img3 from '../assets/images/autobiography 02.jpg'
import img4 from '../assets/images/autobiography 03.jpg'
import img5 from '../assets/images/autobiography 04.jpg'
import img6 from '../assets/images/autobiography 05.jpg'
import img7 from '../assets/images/autobiography 06.jpg'

export default function MyTab() {
    return (
        <div className="lg:px-0 px-8 relative z-[0]">



            <Tabs className="flex flex-wrap justify-center my-10 lg:gap-0 gap-2 " aria-label="Default tabs" style="default">


                <Tabs.Item className="lg:px-4 px-2 py-2 bg-[#3cca79] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
                    active title="FICTION"
                >
                    <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
                        id="" role="" aria-labelledby="">
                        <div className="mx-auto"><a href="assets/images/fiction 01.jpg"><img
                            src={img24} /></a></div>
                        <div className="mx-auto"><a href="assets/images/fiction 02.jpg"><img
                            src={img25} /></a></div>
                        <div className="mx-auto"><a href="assets/images/fiction 03.jpg"><img
                            src={img26} /></a></div>
                        <div className="mx-auto"><a href="assets/images/fiction 04.jpg"><img
                            src={img27} /></a></div>
                        <div className="mx-auto"><a href="assets/images/fiction 05.jpg"><img
                            src={img28} /></a></div>
                        <div className="mx-auto"><a href="assets/images/fiction 06.jpg"><img
                            src={img29} /></a></div>
                    </div>


                </Tabs.Item>



                <Tabs.Item title="NON-FICTION" >
                    <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
                        id="slide-2" role="tabpanel" aria-labelledby="slide-2-tab">
                        <div className="mx-auto"><a href="assets/images/non-fiction 01.jpg"><img
                            src={img47} /></a></div>
                        <div className="mx-auto"><a href="assets/images/non-fiction 02.jpg"><img
                            src={img48} /></a></div>
                        <div className="mx-auto"><a href="assets/images/non-fiction 03.jpg"><img
                            src={img49} /></a></div>
                        <div className="mx-auto"><a href="assets/images/non-fiction 04.jpg"><img
                            src={img50} /></a></div>
                        <div className="mx-auto"><a href="assets/images/non-fiction 05.jpg"><img
                            src={img51} /></a></div>
                        <div className="mx-auto"><a href="assets/images/non-fiction 06.jpg"><img
                            src={img52} /></a></div>
                    </div>
                </Tabs.Item>
                <Tabs.Item title="BIOGRAPHY" >
                    <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
                        id="slide-3" role="tabpanel" aria-labelledby="slide-3-tab">
                        <div className="mx-auto"><a href="assets/images/biography 01.jpg"><img
                            src={img2} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/biography 02.jpg"><img
                            src={img3} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/biography 03.jpg"><img
                            src={img4} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/biography 04.jpg"><img
                            src={img5} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/biography 05.jpg"><img
                            src={img6} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/biography 06.jpg"><img
                            src={img7} /></a>
                        </div>
                    </div>
                </Tabs.Item>
                <Tabs.Item title="INFORMATIVE" >
                    <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
                        id="slide-4" role="tabpanel" aria-labelledby="slide-4-tab">
                        <div className="mx-auto"><a href="assets/images/informative 01.jpg"><img
                            src={img30} /></a></div>
                        <div className="mx-auto"><a href="assets/images/informative 02.jpg"><img
                            src={img31} /></a></div>
                        <div className="mx-auto"><a href="assets/images/informative 03.jpg"><img
                            src={img32} /></a></div>
                        <div className="mx-auto"><a href="assets/images/informative 04.jpg"><img
                            src={img33} /></a></div>
                        <div className="mx-auto"><a href="assets/images/informative 05.jpg"><img
                            src={img34} /></a></div>
                        <div className="mx-auto"><a href="assets/images/informative 06.jpg"><img
                            src={img35} /></a></div>
                    </div>
                </Tabs.Item>
                <Tabs.Item title="AUTOBIOGRAPHY" >
                    <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
                        id="slide-6" role="tabpanel" aria-labelledby="slide-6-tab">
                        <div className="mx-auto"><a href="assets/images/autobiography 01.jpg"><img
                            src={img2} /></a></div>
                        <div className="mx-auto"><a href="assets/images/autobiography 02.jpg"><img
                            src={img3} /></a></div>
                        <div className="mx-auto"><a href="assets/images/autobiography 03.jpg"><img
                            src={img4} /></a></div>
                        <div className="mx-auto"><a href="assets/images/autobiography 04.jpg"><img
                            src={img5} /></a></div>
                        <div className="mx-auto"><a href="assets/images/autobiography 05.jpg"><img
                            src={img6} /></a></div>
                        <div className="mx-auto"><a href="assets/images/autobiography 06.jpg"><img
                            src={img7} /></a></div>
                    </div>
                </Tabs.Item>
                <Tabs.Item title="MEMOIR" >
                    <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 lg:gap-8 gap-2"
                        id="slide-7" role="tabpanel" aria-labelledby="slide-7-tab">
                        <div className="mx-auto"><a href="assets/images/memoir 01.jpg"><img
                            src={img41} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/memoir 02.jpg"><img
                            src={img42} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/memoir 03.jpg"><img
                            src={img43} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/memoir 04.jpg"><img
                            src={img44} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/memoir 05.jpg"><img
                            src={img45} /></a>
                        </div>
                        <div className="mx-auto"><a href="assets/images/memoir 06.jpg"><img
                            src={img46} /></a>
                        </div>
                    </div>
                </Tabs.Item>

            </Tabs>
        </div>
    );
}
