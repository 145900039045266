// config.js

const config = {
    apiUrl: 'https://softnovacrm.com/api/leads', // Your API endpoint URL
    apiToken: '1|0acelPr1X23h6i7MruGqmi1TBhZyAZxWLtBySE7w', // Your API Token
    websiteName: "Ghostwriting Mentor", // Website Name
    websiteUrl: "https://www.ghostwritingmentor.com/", // Website Url
    phoneNumberDisplay: "(469) 902-7699", // Phone Number Display
    phoneNumberLink: "4699027699", // Phone Number Link
    contactEmail: "support@ghostwritingmentor.com", // Email Address
    brandId: "1", // Brand Id
    uniteId: "1", // Unit Id
    contactAddress: "2911 Turtle Creek Blvd. Suite 300, Dallas, TX, 75219", // Contact Address
};

export default config;