import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home";
// import Footer from "../components/footer"
import ModalProvider from '../components/ModalProvider.js';
import { useState } from 'react'
import Thankyou from "../pages/thankyou.js";

export default function AppRouter() {

  return (
    <>
      <BrowserRouter>
      <ModalProvider>
        <Routes>
          <Route path="/" element={< Home />} />
          <Route path="/thankyou" element={< Thankyou />} />
        </Routes>
        </ModalProvider>
        
        
      </BrowserRouter>

    </>
  );
}
