import img53 from '../assets/images/top-scroll.png'
import img40 from '../assets/images/logo (1).png'
import config from '../config/config'



function Footer() {

    return (
        <>
            <footer>
                <div className="bg-[#1e293d]  lg:pb-[0] pb-20">
                    <div className="max-w-7xl mx-auto">
                        <div
                            className="grid grid-cols-12 items-center lg:gap-20 gap-4 lg:text-left text-center py-10 lg:px-0 px-2 ">
                            <div className="lg:col-span-6 col-span-12 ">
                                <img className="filter brightness-0 invert lg:mx-[0] mx-auto" src={img40}
                                    alt="" />
                                <h1 className="mt-5 text-[#889ec6] lg:text-[18px] text-[12px]   ">{config.websiteName} has brilliant
                                    writers to
                                    accommodate all
                                    genres,
                                    from
                                    fiction novels to business books, memoirs to self-help guides. We also provide turnkey
                                    publishing services for authors globally.</h1>
                                <ul className="mt-5">
                                    <li className="text-[#889ec6]  lg:text-[18px] text-[12px]">Privacy Policy / Terms & Conditions
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:col-span-6 col-span-12 lg:ml-[230px] lg:text-left text-center items-center">
                                <a href={`tel:${config.phoneNumberLink}`} className="flex items-center">
                                    <i className="fa fa-phone text-[#889ec6] text-[]  mr-2"></i>
                                    <span className=" text-white text-[18px] text-[15px] lg:mx-[0] mx-auto">
                                        {config.phoneNumberDisplay}
                                    </span>
                                </a>
                                <a href={`mailto:${config.contactEmail}`} className="flex items-center mt-5">
                                    <i className="fa fa-envelope text-[#889ec6] mr-2"></i>
                                    <span className=" text-white text-[18px] text-[15px] lg:mx-[0] mx-auto">
                                        {config.contactEmail}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#1b2536] lg:py-5 py-2 ">
                        <div className="max-w-7xl mx-auto">
                            <div className="grid grid-cols-12 items-center">
                                <div className="lg:col-span-6 col-span-12">
                                    <h1 className="text-[#889ec6] lg:text-left text-center lg:py-0 py-5">{config.websiteName}. All
                                        Rights <span className="lg:inline block">Reserved.</span>
                                    </h1>
                                </div>
                                <div className="lg:col-span-6 col-span-12 ">
                                    <a href=""> <img className="float-right lg:mt-[-50px] mt-[-115px]"
                                        src={img53} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
      
            </footer>
        </>
    );
}
export default Footer;
