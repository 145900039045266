import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const Timer = () => {

    // Render function for displaying hours
    const renderHours = ({ remainingTime }) => {
        if (remainingTime === 0) {
            return <div className="timer">Time's up!</div>;
        }

        const hours = Math.floor(remainingTime / 3600);
        return (
            <div className="timer">
                <div className="time">{hours.toString().padStart(2, '0')} Hours</div>
            </div>
        );
    };

    const renderMinutes = ({ remainingTime }) => {
        if (remainingTime === 0) {
            return <div className="timer">Time's up!</div>;
        }

        const minutes = Math.floor(remainingTime / 60);
        return (
            <div className="timer">
                <div className="time">{minutes.toString().padStart(2, '0')} Minutes</div>
            </div>
        );
    };

    const renderSeconds = ({ remainingTime }) => {


        const seconds = remainingTime % 60;
        return (
            <div className="timer">
                <div className="time">{seconds.toString().padStart(2, '0')} Seconds</div>
            </div>
        );
    };

    return (
        <div className="flex gap-4 " style={{ width: '120px', height: '120px' }}>

            <CountdownCircleTimer
                isPlaying
                duration={3600}
                size={120}
                strokeWidth={10}
                colors={[["#008c3a"], ["#008c3a"], ["#008c3a"]]}
            >
                {renderHours}
            </CountdownCircleTimer>


            <CountdownCircleTimer
                isPlaying
                duration={480}
                size={120}
                strokeWidth={10}
                colors={[["#3c3c3c"], ["#3c3c3c"], ["#3c3c3c"]]}
            >
                {renderMinutes}
            </CountdownCircleTimer>


            <CountdownCircleTimer
                isPlaying={true}
                duration={60}
                size={120}
                strokeWidth={10}
                colors={[["#008c3a"], ["#FF4500"], ["#FF0000"]]}
            >
                {renderSeconds}
            </CountdownCircleTimer>
        </div>
    );
};

export default Timer;
