import img14 from '../assets/images/btm-logo1.jpg'
import img15 from '../assets/images/btm-logo2.jpg'
import img16 from '../assets/images/btm-logo3.jpg'
import img17 from '../assets/images/btm-logo4.jpg'
import img18 from '../assets/images/btm-logo5.jpg'
import img19 from '../assets/images/btm-logo6.jpg'
import img20 from '../assets/images/call.png'
import img21 from '../assets/images/cta-book.jpg'
import img23 from '../assets/images/features-img.jpg'
import img36 from '../assets/images/icon1.jpg'
import img37 from '../assets/images/icon2 (1).jpg'
import img38 from '../assets/images/icon2.jpg'
import img39 from '../assets/images/icon3.jpg'
import img40 from '../assets/images/logo (1).png'
import img53 from '../assets/images/top-scroll.png'
import Header from '../components/header.js'
import Footer from '../components/footer.js'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import MyTab from '../components/tab.js'
import { ModalContext } from '../components/ModalProvider';
import react, { useContext} from 'react'
import config from '../config/config.js'


export default function Home() {

  const { openModal } = useContext(ModalContext);
  
  return (
    <>
      <Header />
      <section>
        <div className="max-w-full mx-auto">
          <div className="grid grid-cols-12">
            <div className="col-span-4  bg-[#fa8900] lg:p-10  p-2">
              <div className="gap-2 items-center lg:flex justify-center">
                <img className="lg:mx-[0] lg:w-auto w-[40px] mx-auto lg:w-auto " src={img37}
                  alt="" />
                <h1
                  className="text-white line-h-[23px] lg:text-[16px] text-[14px] font-medium text-center lg:text-left">
                  Affordable <span className="block">Prices</span>
                </h1>
              </div>
            </div>
            <div className="col-span-4  bg-[#3852f3] lg:p-10  p-2">
              <div className="gap-2 items-center lg:flex justify-center">
                <img className="lg:mx-[0] lg:w-auto w-[40px] mx-auto" src={img36} alt="" />
                <h1
                  className="text-white line-h-[23px] lg:text-[16px] text-[14px] font-medium  text-center  lg:text-left">
                  Quickest
                  <span className="block">Turnaround Time</span>
                </h1>
              </div>
            </div>
            <div className="col-span-4  bg-[#00c581] lg:p-10  p-2">
              <div className="gap-2 items-center lg:flex justify-center">
                <img className="lg:mx-[0] lg:w-auto w-[40px] mx-auto" src={img39} alt="" />
                <h1
                  className="text-white line-h-[23px] lg:text-[16px] text-[14px] font-medium text-center  lg:text-left">
                  24/7 Professional
                  <span className="block">Support</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-10">
        <div className="max-w-7xl mx-auto lg:px-0 px-2">
          <div className="grid grid-cols-12 lg:gap-8 gap-4 items-center">
            <div className="lg:col-span-8 col-span-12">
              <h1
                className="font-[600] lg:text-[32px] text-[22px] line-h-[normal] lg:text-left text-center  text-[#232323]">
                The world is waiting for your book
              </h1>
              <p className="lg:text-left text-center text-[14px] text-[12px] mt-5">
                Every great advancement is born out of a fascinating work of
                imagination. We, at {config.websiteName}, will take that step
                forward for you. Every story deserves to be told and heard.
                Therefore, we invite you to experience the work of industry's most
                professional yet extremely innovative ghostwriters, all under one
                roof.
              </p>
              <p className="lg:text-left text-center text-[14px] text-[12px] mt-5">
                We have a track record of delivering thousands of successful
                publications – meticulously written, edited, designed and
                published. Many of the published works we have done for our
                clients have created a buzz in the literary sphere and ranked
                among the best sellers. {config.websiteName} is the perfect choice
                to put your ideas on paper. We have writers who can help you tell
                your story, in any genre, from fiction to nonfiction.
              </p>
              <div className="mt-10 lg:text-left text-center">
                <a className="openModal lg:px-8 px-4 py-3  bg-[#00c581] hover:bg-transparent  lg:text-[18px] text-[17px] text-white  hover:text-[#44c97c]  border-2 border-[#44c97c] rounded-full"
                  href="javascript:;"  onClick={openModal} >LET'S GET STARTED</a>
              </div>
            </div>
            <div className="lg:col-span-4 col-span-12">
              <div className="grid grid-cols-12 leading-[0.1]">
                <div className="col-span-6 ">
                  <ul>
                    <li className="mt-5 text-[14px] font-medium  gap-2 ">
                      <i className="fa fa-check  lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Fiction
                    </li>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check  lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Non-Fiction
                    </li>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check  lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Biography
                    </li>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check  lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Informative
                    </li>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check  lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Autobiography
                    </li>
                  </ul>
                </div>
                <div className="col-span-6  lg:ml-[-65px]">
                  <ul>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check lg:text-[16px] text-[16px]   text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Memoirs
                    </li>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Action &
                      Adventure
                    </li>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Western
                    </li>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Anthology
                    </li>
                    <li className="mt-5 text-[14px] font-medium">
                      <i className="fa fa-check lg:text-[16px] text-[16px]  text-green-500 mr-2"
                        aria-hidden="true"></i>
                      Romance
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-10">
        <div className="bg-[#ededed] lg:py-20 py-5">
          <div className="max-w-7xl mx-auto lg:px-0 px-2">
            <div className="grid grid-cols-12 ">
              <div className="col-span-12 text-center">
                <h1 className="lg:text-[32px] text-[22px] font-[700] text-[#232323] leading-[1.1]">
                  We Have Helped Thousands Of Writers Get Their Work Published
                </h1>
                <p className=" mx-auto lg:text-[18px] text-[13px] text-[#676767] mt-5 lg:w-4/6">
                  Our diversified talent pool with multiple unique skillsets has
                  enabled us to specialize in various genres and cater to the
                  ghostwriting needs of clients from varying industries and
                  professional spheres.
                </p>
              </div>
            </div>
            <MyTab />
            {/* <div className="md:w-7/12  lg:px-0 px-8 mx-auto">
              <ul className="flex flex-wrap justify-center my-10 lg:gap-0 gap-2 " id="default-tab"
                data-tabs-toggle="#default-tab-content" role="tablist">
                <li className="me-2" role="presentation">
                  <button
                    className="lg:px-4 px-2 py-2 bg-[#3cca79] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
                    id="slide-1-tab" data-tabs-target="#slide-1" role="tab">FICTION</button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className="lg:px-4 px-2 py-2 bg-[#3cca79] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
                    id="slide-2-tab" data-tabs-target="#slide-2" role="tab">NON-FICTION</button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className="lg:px-4 px-2 py-2 bg-[#3cca79]  lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px] "
                    id="slide-3-tab" data-tabs-target="#slide-3" type="button" role="tab"
                    aria-controls="settings" aria-selected="false">BIOGRAPHY</button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className="lg:px-4 px-2 py-2 bg-[#3cca79] hover:bg-[] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
                    id="slide-4-tab" data-tabs-target="#slide-7" type="button" role="tab"
                    aria-controls="contacts" aria-selected="false">INFORMATIVE</button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className="lg:px-4 px-2 py-2 bg-[#3cca79] hover:bg-[] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
                    id="slide-6-tab" data-tabs-target="#slide-6" type="button" role="tab"
                    aria-controls="slide-6" aria-selected="false">AUTOBIOGRAPHY</button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className="lg:px-4 px-2 py-2 bg-[#00c581] hover:bg-[#00c581] lg:text-[14px] text-[12px] text-white hover:text-white rounded-[5px]"
                    id="slide-7-tab" data-tabs-target="#slide-7" type="button" role="tab"
                    aria-controls="slide-6" aria-selected="false">MEMOIR</button>
                </li>
              </ul>
            </div>
           */}
          </div>
        </div>
      </section>
      <section className="text-center my-10">
        <div className="max-w-7xl mx-auto  lg:px-0 px-2">
          <h1 className="lg:text-[36px] text-[17px] font-[600] text-[#333] leading-normal">Our premium ghostwriting and
            publishing
            services are aimed at
            assisting you to achieve your professional
            writing and publishing goals.</h1>
          <img className="mx-auto my-10 lg:mb-[0] mb-[7rem]" src={img23} alt="" />
        </div>
      </section>
      <section className="my-10 lg:mb-10">
        <div className="bg-[#44c97c] py-10">
          <div className="max-w-7xl mx-auto  lg:px-0 px-2">
            <div className="grid grid-cols-12 items-center gap-4">
              <div className="lg:col-span-5 col-span-12">
                <img className="lg:w-full w-[200px] mx-[0] mx-auto lg:mt-[0] mt-[-96px]"
                  src={img21} alt="" />
              </div>
              <div className="lg:col-span-7 col-span-12">
                <h1 className="text-white lg:text-left text-center text-[22px]">We'll help you write
                  the book that could be
                  the next best seller.</h1>
                <p className="text-white lg:text-left text-center lg:text-[47px] font-[700] text-[25px]">Get your
                  best seller written at <span className="text-[#fff18f]">85%</span> OFF</p>
                <div className="grid grid-cols-12 lg:gap-4 gap-2 mt-5 mx-auto ">
                  <div className="lg:col-span-6 col-span-12">
                    <a className="flex items-center " href={`tel:${config.phoneNumberLink}`}>
                      <i className="fa fa-phone text-white text-[20px]" aria-hidden=""></i>
                      <div className="ml-4">
                        <h1 className="text-white lg:text-[14px] text-[12px]">CALL US (TOLL FREE)</h1>
                        <span className=" text-white lg:text-[14px] text-[10px]">
                          {config.phoneNumberDisplay}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="lg:col-span-6 col-span-12">
                    <a className="flex items-center " href="mailto: support@ghostwritingsquad.com">
                      <i className="fa fa-envelope text-white text-[25px]" aria-hidden=""></i>
                      <div className="ml-4">
                        <h1 className="text-white lg:text-[14px] text-[12px]">DISCUSS YOUR IDEAS</h1>
                        <span className=" text-white text-[14px] text-[10px] break-all">
                          support@ghostwritingsquad.com</span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="mt-10 lg:text-left text-center">
                  <a className="openModal lg:px-8 px-4 py-3 bg-[#ec1200] hover:bg-white hover:text-[#ec1200] lg:text-[17px] text-[15px] text-white border-2 border-[#ec1200] rounded-full font-medium"
                    href="javascript:;"  onClick={openModal} >ACTIVATE COUPON NOW</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lg:mb-20">
        <div className="max-w-7xl mx-auto  lg:px-0 px-2">
          <div className="grid grid-cols-12 lg:gap-16 gap-4">
            <div className="lg:col-span-5  col-span-12 lg:text-left text-center">
              <h1
                className="lg:text-[48px] text-[#232323] text-[24px] lg:font-medium font-[600] leading-[48px] leading-[normal]">
                Our
                statured client list is a testament to our
                dedication.</h1>
              <p className="text-[#8a8a8a] lg:text-[18px] text-[15px] mt-10">This is what we have been doing for
                decades and that is why we are
                considered the best in business.</p>
            </div>
            <div className="lg:col-span-7 col-span-12">
              <OwlCarousel className="owl.theme"
                autoplay
                loop
                items="2"
                margin={10}
                autoplayTimeout={1500}
                autoplaySpeed={1800}
                responsive={{
                  0: {
                    items: 1 // Display 2 items on mobile devices
                  },
                  768: {
                    items: 2 // Display 6 items on larger screens
                  }
                }}
              >
                <div className="item">
                  <div className="">
                    <div className="bg-[#f5f5f5] p-5">
                      <p className="text-[#8c8989]">{config.websiteName} made my dream of becoming an author
                        come true.
                        They
                        are some crazy
                        creative people with a lot of ideas and took care of everything from outlining
                        the plot
                        to designing and publishing. They revised the draft times and again to my
                        satisfaction.
                      </p>
                      <h1 className="text-[18px] font-[600] mt-5">Martha Doyle</h1>
                      <span className="text-[#4e4c4c] text-[13px]">Educationist and Author</span>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="">
                    <div className="bg-[#f5f5f5] p-5">
                      <p className="text-[#8c8989]">{config.websiteName} made my dream of becoming an author
                        come true.
                        They
                        are some crazy
                        creative people with a lot of ideas and took care of everything from outlining
                        the plot
                        to designing and publishing. They revised the draft times and again to my
                        satisfaction.
                      </p>
                      <h1 className="text-[18px] font-[600] mt-5">Martha Doyle</h1>
                      <span className="text-[#4e4c4c] text-[13px]">Educationist and Author</span>
                    </div>
                  </div>
                </div>

              </OwlCarousel>
              {/* <div className="owl-carousel owl-cardslider lg:px-0 px-2">
                <div className="item">
                  <div className="">
                    <div className="bg-[#f5f5f5] p-5">
                      <p className="text-[#8c8989]">{config.websiteName} made my dream of becoming an author
                        come true.
                        They
                        are some crazy
                        creative people with a lot of ideas and took care of everything from outlining
                        the plot
                        to designing and publishing. They revised the draft times and again to my
                        satisfaction.
                      </p>
                      <h1 className="text-[18px] font-[600] mt-5">Martha Doyle</h1>
                      <span className="text-[#4e4c4c] text-[13px]">Educationist and Author</span>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="">
                    <div className="bg-[#f5f5f5] p-5">
                      <p className="text-[#8c8989]">{config.websiteName} made my dream of becoming an author
                        come true.
                        They
                        are some crazy
                        creative people with a lot of ideas and took care of everything from outlining
                        the plot
                        to designing and publishing. They revised the draft times and again to my
                        satisfaction.
                      </p>
                      <h1 className="text-[18px] font-[600] mt-5">Martha Doyle</h1>
                      <span className="text-[#4e4c4c] text-[13px]">Educationist and Author</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="mt-10">
        <div className=" mx-auto  lg:py-5 py-2 items-center bg-[#223047] ">
          <div className="max-w-7xl mx-auto">
            <OwlCarousel className="owl.theme"
              autoplay
              loop
              items="6"
              autoplayTimeout={1500}
              autoplaySpeed={1200}
              margin={10}
              responsive={{
                0: {
                  items: 2 // Display 2 items on mobile devices
                },
                768: {
                  items: 6 // Display 6 items on larger screens
                }
              }}
            >
              <div className="item">
                <div className="">
                  <img class="mx-auto" src={img14} />
                </div>
              </div>
              <div className="item">
                <div className="">
                  <img class="mx-auto" src={img15} />
                </div>
              </div>
              <div className="item">
                <div className="">
                  <img class="mx-auto" src={img16} />
                </div>
              </div>
              <div className="item">
                <div className="">
                  <img class="mx-auto" src={img17} />
                </div>
              </div>
              <div className="item">
                <div className="">
                  <img class="mx-auto" src={img18} />
                </div>
              </div>
              <div className="item">
                <div className="">
                  <img class="mx-auto" src={img19} />
                </div>
              </div>

            </OwlCarousel>
          </div>
        </div>
      </section>
      <Footer />
      
    </>
  );
}